import { Endpoints } from '@/enums/endpoints';
import { QueryOptions } from '@/types/queryOptions';
import { RequestError } from '@/types/requestError';
import { Responses } from '@/types/responses';
import { getErrorNames } from '@/utilities/getErrorName';
import createAxiosInstance from '@/utilities/http';
import { QueryClient, QueryFunctionContext, useQuery } from '@tanstack/react-query';

export const fetching = async (context: QueryFunctionContext) => {
	const { baseUrl, accessToken, slug } = context.queryKey[1] as QueryOptions;

	const HTTP = createAxiosInstance({ baseUrl, accessToken });

	try {
		const { data } = await HTTP.get(Endpoints.PAGES + slug + '/');
		return data;
	} catch (error: any) {
		throw Error(getErrorNames(error)[0], { cause: 'Error fetching page' });
	}
};

export const pagePrefetching = (queryClient: QueryClient, options: QueryOptions) => {
	return queryClient.prefetchQuery<Responses.Pages.Fetching, RequestError>([Endpoints.PAGES, options], fetching);
};

export const usePagesFetching = (options: QueryOptions) => {
	return useQuery<Responses.Pages.Fetching, RequestError>([Endpoints.PAGES, options], fetching);
};
