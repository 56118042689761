export enum Pages {
	LOGIN = 'login',
	REGISTER = 'register',
	ACCOUNT = 'account',
	PROJECTS = 'projects',
	CAREERS = 'careers',
	CREDITS = 'credits',
	SERVICES = 'services',
	ABOUT_US = 'about-us',
	CONTACT_US = 'contact-us',
	BLOG = 'blog',
	SUPPORT = 'support',
	SEARCHED = 'searched',
	PRODUCTS = 'products',
	CART = 'cart',
	CHECKOUT = 'checkout',
	SHOP = 'shop',
	WISHLIST = 'wishlist',
	MENU = 'menu',
	BOOK_TABLE = 'book-table',
	FRAGRANCES = 'fragrances',
	OFFERS = 'offers',
	VIDEOS = 'videos',
	BLOGGERS = 'bloggers'
}
