import { Cookies } from '@/enums/cookies';
import { Endpoints } from '@/enums/endpoints';
import { Pages } from '@/enums/pages';
import { Routes } from '@/enums/routes';
import { SubPages } from '@/enums/subPages';
import { blogsPrefetching } from '@/services/blogs/get/listing';
import { carouselsPrefetching } from '@/services/carousels/get/listing';
import { categoriesPrefetching } from '@/services/categories/index/get/listing';
import { factorsPrefetching, useFactorsListing } from '@/services/gateways/factors/get/listing';
import { gatewaysPrefetching } from '@/services/gateways/index/get/listing';
import { fetching as pageFetching, pagePrefetching } from '@/services/pages/get/fetching';
import { pageIconsPrefetching, usePageIconsListing } from '@/services/pages/icons/get/listing';
import { productsPrefetching } from '@/services/products/index/get/listing';
import { wishlistsPrefetching } from '@/services/products/wishlist/get/listing';
import { regionsPrefetching, useRegionsListing } from '@/services/users/regions/get/listing';
import { shipmentsPrefetching } from '@/services/users/shipments/get/listing';
import { useAuthStore } from '@/stores/auth';
import { useCurrencyStore } from '@/stores/currency';
import { useDomainStore } from '@/stores/domain';
import { useRegionsStore } from '@/stores/regions';
import { AppThemeData } from '@/types/appTheme';
import { Domain } from '@/types/domain';
import { Page } from '@/types/page';
import { QueryOptions } from '@/types/queryOptions';
import { QueryState } from '@/types/queryState';
import { RequestError } from '@/types/requestError';
import { Responses } from '@/types/responses';
import { RouterQuery } from '@/types/routerQuery';
import { Session } from '@/types/session';
import { getColorPalette } from '@/utilities/colors';
import { getDomain } from '@/utilities/domains';
import { getThemeByName } from '@/utilities/theme';
import { QueryClient, dehydrate, useQueryClient } from '@tanstack/react-query';
import { CookieValueTypes, deleteCookie, getCookie, setCookie } from 'cookies-next';
import { GetServerSideProps, NextPage } from 'next';
import loadNamespaces from 'next-translate/loadNamespaces';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const PageContent = dynamic(() => import('@/components/PageContent'));

type Props = {
	domain: Domain;
	session: Session | null;
};

export const getServerSideProps: GetServerSideProps = async ({ req, res, params, locale }) => {
	const queryClient = new QueryClient();

	const domainCookie: CookieValueTypes = getCookie(Cookies.DOMAIN, { req, res });
	const sessionCookie: CookieValueTypes = getCookie(Cookies.SESSION, { req, res });

	const domain: Domain | null = await getDomain({ domainCookie, queryClient, req });
	const session: Session | null = sessionCookie ? JSON.parse(sessionCookie as string) : null;

	const slug = params!.slug as undefined | string;

	if (!domain) throw Error('Domain unavailable');
	else setCookie(Cookies.DOMAIN, JSON.stringify(domain), { req, res });

	const isShop: boolean = slug === Pages.SHOP;
	const isBlog: boolean = slug === Pages.BLOG;
	const isAccount: boolean = slug === Pages.ACCOUNT;
	const isCheckout: boolean = slug === Pages.CHECKOUT;
	const isWishlist: boolean = slug === Pages.WISHLIST;

	if ((isAccount && !sessionCookie) || (isWishlist && !sessionCookie)) {
		deleteCookie(Cookies.SESSION, { req, res });
		const destination = Routes.ROOT;
		return { redirect: { destination, permanent: true } };
	} else if (isAccount && sessionCookie) {
		const destination = `/${Pages.ACCOUNT}/${SubPages.MY_DETAILS}`;
		return { redirect: { destination, permanent: true } };
	}

	const requests: any[] = [
		pagePrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access, slug }),
		pageIconsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access }),
		categoriesPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access }),
		factorsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access })
	];

	if (isShop) {
		requests.push(
			productsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access }),
			carouselsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access })
		);
	}

	if (isBlog) {
		requests.push(blogsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access }));
	}

	if (isWishlist) {
		requests.push(wishlistsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access }));
	}

	if (isCheckout) {
		requests.push(
			gatewaysPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access }),
			shipmentsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access }),
			regionsPrefetching(queryClient, { baseUrl: domain.backend_url, accessToken: session?.access, getCountries: true })
		);
	}

	await Promise.all(requests);

	const dehydratedState = JSON.parse(JSON.stringify(dehydrate(queryClient)));
	const translations = await loadNamespaces({
		locale,
		pathname: '/[slug]',
		loadLocaleFrom: (lang, ns) => import(`@/templates/${domain.theme.name}/locales/${lang}/${ns}.json`).then((m) => m.default)
	});

	return { props: { session, domain, dehydratedState, ...translations } };
};

const PageTemplate: NextPage<Props> = ({ domain, session }) => {
	const [themeData, setThemeData] = useState<AppThemeData | null>(null);
	const [page, setPage] = useState<QueryState<null | Page>>({ isLoading: true, error: null, data: null });

	const queryClient = useQueryClient();
	const { query, locale } = useRouter();
	const { slug } = query as RouterQuery;

	const { setDomain } = useDomainStore.getState();
	const { setSession } = useAuthStore.getState();
	const { setFactors } = useCurrencyStore.getState();
	const { setCountries } = useRegionsStore.getState();

	const { data: factors } = useFactorsListing({ baseUrl: domain.backend_url, accessToken: session?.access });
	const { data: countries } = useRegionsListing({ baseUrl: domain.backend_url, accessToken: session?.access, getCountries: true });
	const { data: icons } = usePageIconsListing({ baseUrl: domain.backend_url, accessToken: session?.access });

	const themeName = domain.theme.name.toLowerCase();
	const palette = getColorPalette(domain);

	useEffect(() => {
		const initializeThemeData = async (name: string) => {
			const theme = await getThemeByName(name);
			setThemeData(theme(palette, locale));
		};

		if (themeName) initializeThemeData(themeName);
	}, [themeName]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setDomain({ ...domain, links: icons?.results });
	}, [domain]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSession(session);
	}, [session]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setFactors(factors);
	}, [factors]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setCountries(countries?.results);
	}, [countries?.results]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const BANNED_SLUGS = [Routes.ROOT, Pages.CREDITS, Pages.PRODUCTS] as string[];

		if (slug && BANNED_SLUGS.includes(slug)) return;

		const InitializePage = async (baseUrl: string, slug: string) => {
			try {
				const options: QueryOptions = { baseUrl, accessToken: session?.access, slug };
				const response = await queryClient.fetchQuery<Responses.Pages.Fetching, RequestError>([Endpoints.PAGES, options], pageFetching);
				setPage({ isLoading: false, error: null, data: response });
			} catch (error: any) {
				setPage({ isLoading: false, error, data: null });
			}
		};

		if (domain && domain.backend_url && slug) InitializePage(domain.backend_url, slug);
	}, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

	return themeName && themeData && <PageContent themeName={themeName} themeData={themeData} slug={slug!} page={page.data} />;
};

export default PageTemplate;
